/**
 * Created by mheide on 06.11.2015.
 */
jQuery (function () {
    var timeout;

    // coverflow
    var swiper = new Swiper('#slideshow .swiper-container', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        //initialSlide: 3,
        preloadImages: true,
        lazyLoading: true,
        autoplay: 2500,
        coverflow: {
            rotate: 10,
            stretch: 0,
            depth: 150,
            modifier: 1,
            slideShadows : true
        }
    });

    // slider
    var swiper = new Swiper('#twofilials .swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 'auto',
        lazyLoading: true,
        autoplay: 2500,
        loop: true,
        spaceBetween: 30,
        effect: 'fade'
    });

    // set header img
    jQuery (".overlay img:visible").width (jQuery (window).width ());
    if (jQuery (window).height () > jQuery (".overlay img:visible").height ()) {
        var diff = jQuery (window).height () - jQuery (".overlay img:visible").height ();
        jQuery (".overlay img:visible").css ("padding-top", (diff / 2) + "px").css ("padding-bottom", (diff / 2) + "px");
    }

    // scroll header
    var before = jQuery ("header").outerHeight(true);
    jQuery (window).scroll (function(e) {
        if (jQuery (this).scrollTop() > 50) {
            clearTimeout(timeout);
        }

        if (!jQuery (".overlay").is(":hidden") && (jQuery (this).scrollTop() + Math.abs (parseInt (jQuery (".overlay").css ("margin-top")))) < jQuery (".overlay").height()) {
            jQuery (".overlay").css ("margin-top", "-"+ jQuery (this).scrollTop()*2 + "px");
        } else if ((jQuery (this).scrollTop() + Math.abs (parseInt (jQuery (".overlay").css ("margin-top")))) > jQuery (".overlay").height() && !jQuery (".overlay").is(":hidden")) {
            jQuery (".overlay").fadeOut();

            return false;
        }

        if (jQuery (".overlay").is(":hidden") && jQuery (this).scrollTop() > (jQuery ("header").outerHeight(true) - 53) && jQuery ("header.scrolled").length < 1) {
            jQuery ("header").addClass ("scrolled", 1000, "swing");
            var after = jQuery ("header").outerHeight(true);
            jQuery ("#content").css ("margin-top", before + "px");
        } else  if (jQuery ("header.scrolled").length > 0 && jQuery (this).scrollTop() < (before - jQuery ("header.scrolled").outerHeight(true))) {
            jQuery ("header").removeClass ("scrolled", 1000, "swing");
            jQuery ("#content").css ("margin-top", 0);
        }

    });

    /* Show Overlay */
    jQuery (".overlay").on ("click", closeOverlay);
    timeout = setTimeout(closeOverlay, 4 * 1000);

    /* show more Brand Logos*/
    jQuery (".map-box .link").on ("click", resizeMap);

    jQuery (".brands .link").on ("click", function () {
        jQuery (".brands .more").slideToggle ();
        if (jQuery(".brands .down.link").is(":hidden")) {
            jQuery (".brands .down.link").fadeIn();
            jQuery(".brands .up.link").fadeOut();
        } else {
            jQuery (".brands .down.link").fadeOut();
            jQuery(".brands .up.link").fadeIn();
        }
    });

    /* Send Contact Form */
    jQuery ('#contact form').submit (function () {
		jQuery ("#contact form .alert-success").addClass("hidden");
		jQuery ("#contact form .alert-danger").addClass("hidden");
		jQuery (".g-recaptcha").parent ().removeClass ("has-error");
		jQuery (".g-recaptcha").parent ().find (".help-block").html ("");
		
        jQuery.ajax ({
                type:   "POST",
                url:    jQuery(this).attr('action'),
                data: jQuery(this).serialize(),
                success: function(data){
                    var obj = jQuery.parseJSON(data);
                    if (obj.type == "success") {
                        jQuery ("#contact form .alert-success").removeClass("hidden");
                    } else {
						if (typeof (grecaptcha) != "undefined") {
							grecaptcha.reset ();
						}
						
                        jQuery ("#contact form .alert-danger").removeClass("hidden");
						if (obj.type == "error" && obj.field == "g-recaptcha") {
							jQuery (".g-recaptcha").parent ().addClass ("has-error");
							jQuery (".g-recaptcha").parent ().find (".help-block").html ('<ul class="list-unstyled"><li>'+ obj.text +'</li></ul>');
						}
                    }
                },
                failure: function(errMsg) {
                    jQuery ("#contact form .alert-danger").removeClass("hidden");
                }
            }
        );

        return false;
    });

    /* Brand Logo show Text */
    jQuery ('.brand-logo').on ("click", function () {
        jQuery ('.brand-logo').show ();
        jQuery ('.brand-text').hide ();

        var elem = this;
        jQuery (elem).fadeOut(300, function() {
            jQuery (elem).next().fadeIn(300);
        });
    });

    jQuery ('#slider .sliderbox .prev-btn').click (function () {
        var elem = jQuery ('#slider .sliderbox ul.nav li.active').prev().find('a');
        if (elem.length > 0) {
            elem.trigger ("click");
            jQuery ('#slider .sliderbox .next-btn').removeClass('inactive');
        } else {
            jQuery ('#slider .sliderbox ul.nav li:last').find('a').trigger ('click');
        }
    });

    jQuery ('#slider .sliderbox .next-btn').click (function () {
        var elem = jQuery ('#slider .sliderbox ul.nav li.active').next().find('a');
        if (elem.length > 0) {
            elem.trigger ("click");
        } else {
            jQuery ('#slider .sliderbox ul.nav li:first').find('a').trigger ('click');
        }
    });
});

function closeOverlay () {
    var overlay = jQuery (".overlay");
    if (!overlay.is (":hidden")) {
        overlay.fadeOut();
        jQuery("header").fadeIn();
    }

    return false;
}

function resizeMap () {
    var mapObj = jQuery ("#map");
    if (mapObj.hasClass ("big")) {
        mapObj.removeClass("big");

        jQuery (".map-box .link.down").fadeIn();
        jQuery (".map-box .link.up").fadeOut();
    } else {
        mapObj.addClass("big");

        jQuery (".map-box .link.down").fadeOut();
        jQuery (".map-box .link.up").fadeIn();
    }
    //TODO ADD FIT BOUNDS FOR OSM
}
